<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import printSmu from '@/components/print-smu'

export default {
  page: {
    title: "Print Label SMU",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    

    return {
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  methods: {
    createPDF () {
      printSmu(1)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <b-button id="invprint" variant="success" @click="createPDF">Print</b-button>
        </div>
      </div>
    </div>
  </Layout>
</template>